<template>
  <div class="header-box">
    <img src="@/assets/images/logo.png" @click="router.push('/'); showList = false;" />
    <img v-if="!showList" src="@/assets/images/home/more-icon.png" @click="showList = true" />
    <img v-if="showList" src="@/assets/images/show-list.png" @click="showList = false" />
  </div>
  <div class="header-box-list" v-if="showList" @click.stop="showList = false">
    <div @click.stop="null">
      <div v-for="(item, index) in routerList" :key="index" class="navigation-list"
        :class="showListIndex == index ? 'active' : ''">
        <div class="title" @click="showListIndex == index ? showListIndex = -1 : showListIndex = index">
          <span>{{ item.title }}</span>
          <img src="@/assets/images/home/prev-icon.png" />
        </div>
        <div v-show="showListIndex == index" v-for="(e, i) in item.value" :key="i" @click="changeRouter(e, e.titleIndex)">{{
          e.name }}</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const showListIndex = ref(-1)
const routerList: any = [
  {
    title: '产品服务',
    value: [
      {
        name: '数智产品',
        path: '/product_service',
        titleIndex: 1,
        pageTitle: ['产品服务', '数智产品']
      },
      {
        name: '渠道拓展',
        path: '/channel_expansion',
        titleIndex: 1,
        pageTitle: ['产品服务', '渠道拓展']
      }
    ]
  },
  {
    title: '解决方案',
    value: [
      {
        name: '文娱解决方案',
        path: '/solution',
        index: 0,
        titleIndex: 2,
        pageTitle: ['解决方案', '文娱解决方案']
      },
      {
        name: '工业解决方案',
        path: '/solution',
        index: 1,
        titleIndex: 2,
        pageTitle: ['解决方案', '工业解决方案']
      },
      {
        name: '教育解决方案',
        path: '/solution',
        index: 2,
        titleIndex: 2,
        pageTitle: ['解决方案', '教育解决方案']
      },
      {
        name: '医疗解决方案',
        path: '/solution',
        index: 3,
        titleIndex: 2,
        pageTitle: ['解决方案', '医疗解决方案']
      },
      {
        name: '军警部队解决方案',
        path: '/solution',
        index: 4,
        titleIndex: 2,
        pageTitle: ['解决方案', '军警部队解决方案']
      }
    ]
  },
  {
    title: '关于我们',
    value: [
      {
        name: '公司介绍',
        path: '/about_us_view',
        titleIndex: 5,
        pageTitle: ['关于我们', '公司介绍']
      },
      {
        name: '新闻动态',
        path: '/about_us',
        titleIndex: 5,
        pageTitle: ['关于我们', '新闻动态']
      },
      {
        name: '加入我们',
        path: '/about_detail',
        titleIndex: 5,
        pageTitle: ['关于我们', '加入我们']
      }
    ]
  },
  {
    title: '合作咨询',
    value: [
      {
        name: '何洋 13225532650',
        path: '',
      },
    ]
  },
]
const showList = ref(false)
const changeRouter = (e: any, titleIndex: number) => {
  if (!e.path) return
  if (e.path == '/solution') {
    router.push(`${e.path}?type=${e.index}&pathName=${e.pageTitle[0]},${e.pageTitle[1]}`)
    store.state.programmeIndex = e.index
  } else {
    router.push(`${e.path}?pathName=${e.pageTitle[0]},${e.pageTitle[1]}`);
  }
  store.state.titleIndex = titleIndex;
  store.state.pageTitle = [...e.pageTitle]
  showList.value = false
}
</script>
<style scoped lang="less">
.header-box {
  width: 750rem;
  height: 72rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28rem 0 16rem;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  &>img:first-child {
    width: auto;
    height: 68rem;
  }

  &>img:last-child {
    width: 32rem;
    height: 32rem;
  }
}

.header-box-list {
  position: fixed;
  left: 0;
  top: 72rem;
  width: 100vw;
  height: calc(100vh - 72rem);
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;

  &>div {
    padding: 18rem 35rem 0;
    // border-bottom: 2rem solid #757171;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0rem 2rem 0rem 0rem rgba(255, 255, 255, 0.5);
  }

  .navigation-list {
    border-bottom: 0.39rem solid rgba(255, 255, 255, 0.5);
    color: #fff;

    &>div {
      text-align: left;
      font-size: 20rem;
      line-height: 28rem;
      margin-bottom: 16rem;
    }

    .title {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54rem;
      line-height: 54rem;
      font-size: 24rem;
      margin-bottom: 0;

      img {
        width: 16rem;
        height: 16rem;
      }
    }

    &.active {
      padding-bottom: 8rem;

      .title {
        margin-bottom: 6rem;
      }

      img {
        transform: rotate(-90deg);
      }
    }
  }

  &>div>div:last-child {
    border: none;
  }
}
</style>
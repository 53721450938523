<template>
  <div class="service-wrap">
    <banner-c></banner-c>
    <div class="product-list">
      <div class="first-level-title">
        <div v-for="(item, id) in productTypes" :class="activeType == id ? 'active' : ''" :key="id"
          @click="selectType(id)">
          {{ item.name }}
        </div>
      </div>
      <div class="secondary-title">
        <div v-if="productTypes[activeType]?.value">
          <span :class="activeProduct == item.id ? 'active' : ''"
            v-for="(item, index) in productTypes[activeType].value" :key="index" @click="selectProduct(item.id)">{{
              item.brandName
            }}</span>
        </div>
      </div>
      <div class="product-box">
        <div class="product-introduction">
          <div class="product-info" v-for="(e, i) in products" :key="i"
            @click="toOtherPath(`/product_detail?id=${e.id}&pathName=数智产品,${e.productTypeName},${e.productBrandName},${e.productName}`, 1, ['数智产品', e.productTypeName, e.productBrandName, e.productName])">
            <div class="product-img">
              <img :src="e.picList[0]" />
            </div>
            <div class="product-text">
              <div class="product-title">{{ e.productName }}</div>
              <div>{{ e.productIntroduction }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { getProductTypes, getProductProfile, getProducts, getProductList } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import BannerC from '@/components/BannerC.vue';

const router = useRouter()
const store = useStore()

const productTypes: any = ref({})
const activeType = ref(1)
const activeProduct = ref(0)
const products: any = ref([])


const selectType = (id: number) => {
  activeType.value = id;
  getProductsData(id)
}

const selectProduct = (id: number) => {
  activeProduct.value = id
  getProductsData(activeType.value, id)
}

const toOtherPath = (url: string, titleIndex: number, pageTitle: any) => {
  router.push(url);
  store.state.titleIndex = titleIndex;
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  const productCategory = window.localStorage.getItem('product-category');
  const brand = window.localStorage.getItem('brand')
  getProductTypes().then((res: any) => {
    getProductProfile().then((res2: any) => {
      // activeProduct.value = res2[0].brandProfileList[0].id
      res2.forEach((ele: any) => {
        if (!productTypes.value[ele.productTypeId]) {
          productTypes.value[ele.productTypeId] = { name: res[ele.productTypeId], value: [...(ele.brandProfileList || [])] }
        } else {
          productTypes.value[ele.productTypeId].push({ name: res[ele.productTypeId], value: [...(ele.brandProfileList || [])] })
        }
      });


      if (productCategory) {
        Object.keys(productTypes.value).forEach((e) => {
          if (productTypes.value[e].name == productCategory) {
            activeType.value = +e;
            if (brand) {
              productTypes.value[e].value.forEach((e: any) => {
                if (e.brandName == brand) {
                  activeProduct.value = e.id
                }
              })
            }
          }
        })

        window.localStorage.setItem('product-category', '')
        window.localStorage.setItem('brand', '')
      }
      getProductsData(activeType.value, activeProduct.value)
    })
  })
})

const getProductsData = (productTypeId?: number | string, brandId?: number | string) => {
  getProducts({ productTypeId, brandId }).then((res: any) => {
    products.value = { ...res.records }
  })
}
</script>
<style scoped lang="less">
.product-list {
  margin-top: -60rem;

  .first-level-title {
    height: 60rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      position: relative;
      height: 60rem;
      line-height: 60rem;
      font-size: 20rem;
      margin: 0 50rem;
      cursor: pointer;
      font-weight: 400;
      text-align: center;
    }

    .active {
      font-weight: 800;
      color: #D81417;

      &::after {
        content: '';
        width: 51rem;
        height: 2rem;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -25.5rem;
        background-color: #D81417;
      }

      &::before {
        content: '';
        width: 12rem;
        height: 8rem;
        background: url(/public/images/top-red.png);
        background-size: 100% 100%;
        position: absolute;
        right: -23rem;
        top: 50%;
        margin-top: -4rem;
      }
    }
  }

  .secondary-title {
    height: 60rem;
    background: #F7F7F7;
    font-weight: 400;
    font-size: 16rem;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC-Regular;

      span {
        cursor: pointer;
        margin: 0 28rem;
      }

      .active {
        font-weight: 800;
        color: #D81417;
      }
    }
  }

  .product-box {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 45rem;

    .product-introduction {
      width: 1208rem;
      display: inline-block;
      margin-bottom: 100rem;
      text-align: left;

      .product-info {
        width: 292rem;
        height: 436rem;
        background: #F7F7F7;
        border-radius: 4rem;
        padding: 10rem;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin: 0 5rem 10rem;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        .product-img {
          width: 272rem;
          height: 272rem;
          background: #FFFFFF;
          border-radius: 4rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          &:hover img {
            transform: scale(1.28);
          }
        }

        .product-text {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 14rem;
          color: #666666;
          text-align: left;

          .product-title {
            font-weight: bold;
            font-size: 18rem;
            color: #333333;
            text-align: center;
            margin-top: 23rem;
            margin-bottom: 10rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }

          &>div:last-child {
            width: 100%;
            height: 60rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 100rem 0;
}
</style>
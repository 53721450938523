<template>
  <div class="cooperate">
    <img :src="loadUrl.cooperate_bg" />
    <div class="hyst title-font-style-m">行业生态</div>
    <div class="logo-view">
      <div class="hzhb title-font-style-m">合作伙伴</div>
      <div class="level2-title">数智行业头部品牌</div>
      <img class="partners" :src="loadUrl.partners1" />
      <div class="level2-title" style="margin-top: 44rem;">B端/G端合作客户</div>
      <img class="partners" :src="loadUrl.partners2" />
      <div class="kfhz">
        <div class="title-font-style-m">开放合作</div>
        <div class="level2-title">欢迎携手凝丰智能 共建数智化生态</div>
        <div class="open-cooperation-box">
          <open-cooperation></open-cooperation>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import OpenCooperation from '@/components/mobile/OpenCooperation.vue';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

onMounted(async () => {
  const partners1: any = await asynchronousRetrieval('nfznxr-images/partners-1.png');
  const partners2: any = await asynchronousRetrieval('nfznxr-images/partners-2.png');
  const cooperate_bg: any = await asynchronousRetrieval('nfznxr-images/cooperate-bg.jpg');
  const open_cooperation_bg: any = await asynchronousRetrieval('nfznxr-images/open-cooperation-bg.png');
  loadUrl.value = { partners1, partners2, cooperate_bg, open_cooperation_bg }
})
</script>
<style scoped lang="less">
.cooperate {
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding-top: 45.5vw;
  box-sizing: border-box;
  font-family: PingFangSC-Medium;
  margin-bottom: 40rem;
  margin-top: 72rem;

  &>img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
  }

  .level2-title {
    font-weight: 400;
    font-size: 28rem;
    color: #5D5D5D;
    margin-top: 8rem;
    margin-bottom: 22rem;
    line-height: 40rem;
  }

  .hyst {
    position: absolute;
    top: 34rem;
    left: 50%;
    width: 300rem;
    margin-left: -150rem;
    text-align: center;
  }

  .logo-view {
    padding-top: 34rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .kfhz {
    background-color: #fff;
    padding-top: 64rem;

    .open-cooperation-box {
      width: 750rem;
      margin-top: 24rem;
      overflow: hidden;
      overflow-x: auto;
    }
  }

  .partners {
    width: 694rem;
  }
}
</style>